export const MOBILE_BREAKPOINT = 600;
export const cities = [
  "Madison",
  "Boston",
  "Austin",
  "Los Angeles"
];

export const coordinatesDiff = {
  SMALL: {
    sw: [0.03, 0.01],
    ne: [0.03, 0.02],
  },
  LARGE: {
    sw: [0.2, 0.16],
    ne: [0.18, 0.14],
  },
};

export const zoomForSmallCities = 14;
export const zoomForLargeCities = 11;

export const propertyTypeMap = {
  "co-living": "coliving",
  pg: "paying-guest",
  roommate: "roommate",
  "entire-house": "full-house",
  room: "room",
  flatmate: "flatmate",
};

export const propertyTypeMapRev = {
  coliving: "co-living",
  "paying-guest": "pg",
  roommate: "roommate",
  "full-house": "entire-house",
  room: "room",
  flatmate: "flatmate",
};


export const footerLinksCities = {
  madison: {
    name: 'Madison',
    localities: [
      'Madison',
      'Madison',
      'Madison',
      'Madison',
    ]
  },
  boston: {
    name: 'Boston',
    localities: [
      'Boston',
      'Boston',
      'Boston',
      'Boston',
    ]
  },
  austin: {
    name: 'Austin',
    localities: [
      'Evanston',
      'Evanston',
      'Evanston',
      'Evanston',
    ]
  },
  los_angeles: {
    name: 'Los Angeles',
    localities: [
      'Syracuse',
      'Syracuse',
      'Syracuse',
      'Syracuse',
    ]
  }
}

export const citiesMap = {
  'madison': 'Madison',
  'boston': 'Boston',
  'austin': 'Austin',
  'los_angeles': 'Los Angeles',
}

export const propertyTypeNameMap = {
  roommate: 'Roommate',
  // sublet: 'Sublet',
  room: 'Room',
  apartment: 'Apartment',
}

export const propertyTypeOrder = [
  'roommate',
  // 'sublet',
  'room',
  'apartment'
]

export const LEASE_TERMS = [
  'Month-to-month',
  '1M',
  '2M',
  '3M',
  '4M',
  '5M',
  '6M',
  '7M',
  '8M',
  '9M',
  '10M',
  '11M',
  '12M',
]

const POSTING_ORDER = [
  "postingFor"
]

export const getPostingOrder = (postingFor) => {
  const POSTING_ORDER = [
      'postingFor',
  ];

  if (postingFor === 'apartment') {
      POSTING_ORDER.push.apply(POSTING_ORDER, ['apartmentDetails', 2.1])
  }
  else {
      POSTING_ORDER.push.apply(POSTING_ORDER, ['roommatePreference', 'apartmentDetails', 'roomDetails']);
  }

  POSTING_ORDER.push.apply(POSTING_ORDER, [
      'locationDetails',
      'locationMap',
      'rentDetails',
      'amenitiesDetails',
      'titleDetails',
      'imageDetails',
      'loginDetails',
      'planDetails',
      'postingSuccess'
  ])

  return POSTING_ORDER
}
